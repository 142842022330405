import React from "react";
export const EInvoice = (props) => {
  return (
    <div id="einvoice" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>IRIS E-Invoicing</h2>
          <h3>Embracing seamless automation and get compliant with LHDN.</h3>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            {" "}
            <img src="img/einvoice-partner-small.png" className="img-responsive" alt="E-Invoice Partner" />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/einvoice-offer.png" className="img-responsive" alt="E-Invoice Offer" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/einvoice-process.png" className="img-responsive" alt="E-Invoice Process" />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 col-md-offset-1 ">
            <div className="small-section">
              <h3>One-stop platform to view, share and collaborate on your invoices with your customers and suppliers alike while managing the entire communication with the LHDN systems, in a hassle-free manner.
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <div className="small-section">            
              {" "}
              <h-small>Esteemed Clientele</h-small>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <div className="small-section">            
              <img src="img/einvoice-clientele.png" className="img-responsive" alt="E-Invoice Clientele" />{""}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <div className="small-section">            
              <h-small>Interested to be a reseller?</h-small>
            </div>
            <div>
              <a
                  href="#contact"
                  className="btn btn-custom btn-lg page-scroll"
              >
              Get In Touch
              </a>{" "}            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
